import TopBanner from './TopBanner';
import BottomBanner from './BottomBanner';
import Gallery from './Gallery';

import '../css/Home.css'

function Home () {
	return (
		<div className="Home">
			<TopBanner />
			<Gallery />
			<BottomBanner />
		</div>
	);
}		
 
export default Home;