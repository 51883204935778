import React, { Component } from 'react';
 
import bottomBanner from '../images/Bottom Banner.svg';
import '../css/BottomBanner.css';

class BottomBanner extends Component {
	render() {
		return (
			<div className="BottomBanner">
				<img src={bottomBanner} alt="" />
			</div>
		);
	}
}		
 
export default BottomBanner;