import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import '../css/GalleryPhoto.css';

class GalleryPhoto extends Component {

	constructor(props) {
		super(props);
		this.state = {
			server: props.photo.server,
			id: props.photo.id,
			secret: props.photo.secret,
			originalsecret: props.photo.originalsecret,
			size: props.photo.size,
			smallUrl: this.photoUrl(props.photo,'n'),
			bigUrl: this.photoUrl(props.photo,'z'),
		};
	}

	photoUrl(photo, suffix) {
		return "https://live.staticflickr.com/"+photo.server+"/"+photo.id+"_"+photo.secret+"_"+suffix+".jpg";
	}

	render() {

		// render a regular photo size
		return (
			<Link className="GalleryPhoto" to={`/photo/${this.state.server}/${this.state.id}/${this.state.originalsecret}`}>
				<img src={this.state.smallUrl} alt="" />
			</Link>
    	);		
			
	}
		
}

export default GalleryPhoto;