import React, { Component } from 'react';

class Sync extends Component {

	render() {
		return (
			<div className="Sync">
				<a href="https://us-central1-calliope-cadfd.cloudfunctions.net/syncFlickrAlbumManual">Sync Photo Library</a>
			</div>
		);
	}
	
}

export default Sync;