import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDUhNh7tYZA5rnGR8h7BeQrMC3-4IpvXyg",
  authDomain: "calliope-cadfd.firebaseapp.com",
  databaseURL: "https://calliope-cadfd-default-rtdb.firebaseio.com",
  projectId: "calliope-cadfd",
  storageBucket: "calliope-cadfd.appspot.com",
  messagingSenderId: "1035988071872",
  appId: "1:1035988071872:web:c7cdd3929cab4d76e10425",
  measurementId: "G-S6BGRNLR3G"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export default getFirestore(app);