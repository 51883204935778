import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route
} from 'react-router-dom';

import Home from './components/Home';
import Photo from './components/Photo';
import Sync from './components/Sync';

import './css/App.css';

class App extends Component {
	render() {
		return (			
			<Router>
				<div className="App">
					
				<Routes>
					<Route exact path='/' element={< Home />}></Route>
					<Route path='/photo/:server/:id/:secret' element={< Photo />}></Route>
					<Route path='/sync' element={< Sync />}></Route>
				</Routes>		

				</div>
			</Router>
		);
	}
}

export default App;