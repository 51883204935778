import React, { Component } from 'react';
 
import topBanner from '../images/Top Banner.svg';
import '../css/TopBanner.css';

class TopBanner extends Component {
	render() {
		return (
			<div className="TopBanner">
				<img src={topBanner} alt="" />
			</div>
		);
	}
}		
 
export default TopBanner;