import React, { Component } from 'react';
import "./firebase.js";
import { getFirestore, collection, getDocs, query, orderBy, limit } from "firebase/firestore";

import GalleryPhoto from './GalleryPhoto';
import Loader from './Loader';

import '../css/Gallery.css';

class Gallery extends Component {

	constructor(props) {
		super(props);
		this.state = {photos: []};
	}
	
	componentDidMount() {
		this.fetchGalleryPhotos();
	}
	
	// fetch the photoDocs from firebase
	fetchGalleryPhotos() {
		const db = getFirestore();
		const collectionRef = collection(db, 'photos');
		const q = query(collectionRef, orderBy("id", "desc"), limit(100));
		getDocs(q).then((photos) => {
			const data = [];
	    photos.forEach((photoDoc) => {
				data.push(photoDoc.data())
	    });
			this.setState({photos: data});
		});
	}	

	// render the Gallery component
	render() {
		
		const smallSize = 1;
		const bigSize = 2;
		const rowSize = 4;
		
		// ensure each row is filled and not overfilled or under
		var rowSpace = rowSize;
		for (const photo of this.state.photos) {
			const size = (photo.title === "big" && rowSpace>=bigSize) ? bigSize : smallSize;
			rowSpace -= size;
			photo.size = size;
			if (rowSpace <= 0) {
				rowSpace = rowSize;
			}
		}
		
		if (this.state.photos.length == 0) {
			return <Loader />;
		}
		
		return (
			<ul className="Gallery">
				{this.state.photos.map((photo) =>
					<GalleryPhoto photo={photo} />
				)}
			</ul>
		);
	}
	
}

export default Gallery;