import { 
	useNavigate,
	useParams,
	Link
} from "react-router-dom";

import '../css/Photo.scss';

function Photo () {

	let navigate = useNavigate();
	const { server, id, secret } = useParams();	
	
	function goBack() {
		navigate(-1);
	}
	
	return (
		<div className="Photo">
			<img onClick={goBack} src={`https://live.staticflickr.com/${server}/${id}_${secret}_h.jpg`} alt="" className="largephoto" />
		</div>
	);
}
 
export default Photo;